import Amplify from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import Gql from 'graphql-tag';

const cnf = "prod";
const apiendpoint = cnf === "prod" ? "https://aepomfd6md.execute-api.us-east-1.amazonaws.com/prod":"https://xdgjekgxn9.execute-api.us-east-1.amazonaws.com/dev";
const awsbucket = cnf === "prod" ? "hhstorage-prod" : "hhstorage-dev";
const STRIPE_KEY = cnf === "prod" ? "pk_live_81jEBKykkLCe83APWntAP6t600mafBic9F" : "pk_test_0B8kfvJitefmQvn9puE1nQi500JWmXdxtY"

const appleSignIn = cnf === "prod" ? {
    region: "us-east-1",
    userPoolId: "us-east-1_H4ubcobZJ",
    userPoolWebClientId: "35g476oah56v3qfh19orifc3a6",
    identityPoolId: "us-east-1:1e92bfac-6b9e`-45c4-9e71-d902fed1931f"
  } : {
    region: "us-east-1",
    userPoolId: "us-east-1_mO85Tox3V",
    userPoolWebClientId: "1kv333o1h9aqtnumnrv7njd8fc",
    identityPoolId: "us-east-1:fcccf885-a818-4786-a1c6-ab1bb7f161cb"
}

const emailusername = cnf === "prod" ? {
  region: "us-east-1",
  userPoolId: "us-east-1_48bLvBNOb",
  userPoolWebClientId: "5fhqo2gal3eavmfs9k5isqokor",
  identityPoolId: "us-east-1:4870f4d2-220c-4375-916e-3ffa3e220dec"
} : {
  region: "us-east-1",
  userPoolId: "us-east-1_w3vfhsVLN",
  userPoolWebClientId: "7nu3113neuugmcio2soken47o0",
  identityPoolId: "us-east-1:123bcd7e-786a-429d-b829-8223956fdf5b"
}

const phoneSigning = cnf === "prod" ? {
  region: "us-east-1",
  userPoolId: "us-east-1_bn9alMIJ3",
  userPoolWebClientId: "4p3d2ukbj98cvclbgcg6ac8js0",
  identityPoolId: "us-east-1:b15edab8-40c2-4e5b-8852-f632ca5668a8"
} : {
  region: "us-east-1",
  userPoolId: "us-east-1_A3CTTbGOQ",
  userPoolWebClientId: "5mf08r0t4jt4pf4tl9v76fa7vb",
  identityPoolId: "us-east-1:e8903635-0bb1-441f-9315-d59cb1967653"
}

const applegoogle = cnf === "prod" ? {
  region: "us-east-1",
  userPoolId: "us-east-1_H4ubcobZJ",
  userPoolWebClientId: "35g476oah56v3qfh19orifc3a6",
  identityPoolId: "us-east-1:1e92bfac-6b9e`-45c4-9e71-d902fed1931f"
} : {
  region: "us-east-1",
  userPoolId: "us-east-1_mO85Tox3V",
  userPoolWebClientId: "1kv333o1h9aqtnumnrv7njd8fc",
  identityPoolId: "us-east-1:fcccf885-a818-4786-a1c6-ab1bb7f161cb"
}

const { Auth } = Amplify;
let url = apiendpoint+'/graphql';
const Client = new AWSAppSyncClient({
  url,
  region: "us-east-1",
  disableOffline:true,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    credentials: () => Auth.currentCredentials()
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
});
Amplify.configure(applegoogle);

const states = {
  alabama: 'AL',
  alaska: 'AK',
  arizona: 'AZ',
  arkansas: 'AR',
  california: 'CA',
  colorado: 'CO',
  connecticut: 'CT',
  delaware: 'DE',
  florida: 'FL',
  georgia: 'GA',
  hawaii: 'HI',
  idaho: 'ID',
  illinois: 'IL',
  indiana: 'IN',
  iowa: 'IA',
  kansas: 'KS',
  kentucky: 'KY',
  louisiana: 'LA',
  maine: 'ME',
  maryland: 'MD',
  massachusetts: 'MA',
  michigan: 'MI',
  minnesota: 'MN',
  mississippi: 'MS',
  missouri: 'MO',
  montana: 'MT',
  nebraska: 'NE',
  nevada: 'NV',
  'new hampshire': 'NH',
  'new jersey': 'NJ',
  'new mexico': 'NM',
  'new york': 'NY',
  'north carolina': 'NC',
  'north dakota': 'ND',
  ohio: 'OH',
  oklahoma: 'OK',
  oregon: 'OR',
  pennsylvania: 'PA',
  'rhode island': 'RI',
  'south carolina': 'SC',
  'south dakota': 'SD',
  tennessee: 'TN',
  texas: 'TX',
  utah: 'UT',
  vermont: 'VT',
  virginia: 'VA',
  washington: 'WA',
  'west virginia': 'WV',
  wisconsin: 'WI',
  wyoming: 'WY'
}

export {
  phoneSigning,
  emailusername,
  appleSignIn,
  awsbucket,
  apiendpoint,
  applegoogle,
  Gql,
  Client,
  Auth,
  cnf,
  STRIPE_KEY,
  states
}